import React, { useState, useEffect } from "react"
import { Div, Text } from "atomize"
import { navigate } from "gatsby"
import CardHeader from "../common/CardHeader"

const subMenuItems = [
  {
    key: "subscription",
    name: "Subscriptions",
  },
  {
    key: "deliveries",
    name: "Upcoming Delveries",
  },
  {
    key: "billing",
    name: "Billing Info",
  },
]

const AccountProfiles = ({ sections, toggleTab, activeTab }) => {
  // console.log(sections)
  const [subTab, setSubTab] = useState("Subscriptions")

  useEffect(() => {
    if (activeTab === "subscription") setSubTab("Subscriptions")
  }, [activeTab])

  const showSubMenuItems = activeTab => {
    const item = document.querySelector("#subscription-tabs")
    if (activeTab === "subscription") {
      item.style.height = "80px"
      item.style.visibility = "visible"
      item.style.opacity = "1"
      item.style.transition =
        ".2s height ease-in-out, 1s opacity ease-in-out, 1s visibility ease-in-out"
    } else {
      item.style.height = "0"
      item.style.opacity = "0"
      item.style.visibility = "hidden"
      item.style.transition =
        ".6s height ease-in-out, .2s opacity ease-in-out, 1s visibility ease-in-out"
    }
  }

  const navigateMenu = (e, tabs) => {
    e.stopPropagation()
    toggleTab(tabs)
    showSubMenuItems(tabs)
  }

  return (
    <>
      <Div rounded="lg" border="1px solid" borderColor="yellowPink" shadow="1">
        <CardHeader heading="Profile" image="/disk.svg" />

        <Div
          p={{ y: "1.875rem", x: { xs: "1.25rem", lg: "1.875rem" } }}
          bg="yellow"
          shadow="4"
          rounded={{ b: "lg" }}
        >
          {Object.keys(sections).map((tabKey, id) => (
            <Text
              m={{ t: id === 0 ? "0" : "1.25rem" }}
              onClick={() => {
                navigate(`/profile?tab=${sections[tabKey].key}`)
              }}
              textWeight={
                sections[tabKey].key === activeTab ? "bold" : "normal"
              }
              cursor="pointer"
            >
              {sections[tabKey].name}

              {sections[tabKey].key === "subscription" && (
                <Div
                  m={{ l: "1.1rem" }}
                  textWeight="normal"
                  style={{ lineHeight: "100%" }}
                  id="subscription-tabs"
                  h="0"
                  style={{ visibility: "hidden" }}
                >
                  {subMenuItems.map((name, id) => {
                    return (
                      <Div
                        onClick={e => {
                          e.stopPropagation()
                          setSubTab(name.name)
                          toggleTab(name.key)
                        }}
                      >
                        <Text
                          textDecor={
                            activeTab === "subscription" || name.key
                              ? subTab === name.name
                                ? "underline"
                                : "none"
                              : "none"
                          }
                          style={{ lineHeight: "100%" }}
                          p={{ t: "15px" }}
                        >
                          {name.name}
                        </Text>
                      </Div>
                    )
                  })}
                </Div>
              )}
            </Text>
          ))}
        </Div>
      </Div>
    </>
  )
}

export default AccountProfiles
